import { Box, Grid } from "@mui/material";
import "./styles.css";

export default function TagInfo(props) {
  const { label, value, icon } = props;

  return (
    <Box className={"tag-info-container"}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid display={"flex"} flexDirection={"column"}>
          <Grid className="tag-info-label">{label}</Grid>
          <Grid className="tag-info-value">{value}</Grid>
        </Grid>
        <Grid className="tag-info-img">
          <img src={icon} alt={`icon ${label}`} />
        </Grid>
      </Grid>
    </Box>
  );
}
