import { Box, Grid, Typography } from "@mui/material";
import CustomBtn from "../../components/CustomBtn";
import Footer from "../../components/Footer";
import BroomCircle from "../../assets/broom-circle.png";
import BroomStick from "../../assets/broom-stick.png";
import "./home.css";
import { useAddress, useIsLaunchApp } from "../../contexts/common";
import CoinListPrice from "../../components/CoinListPrice";
//Feature icon
import SwapIcon from "../../assets/swap-icon.png";
import StakingIcon from "../../assets/staking-icon.png";
import YeildIcon from "../../assets/yeild-icon.png";

//Partner icon
import MerlinIcon from "../../assets/partner-merlin.png";
import AveIcon from "../../assets/partner-ave.png";
import CoinMarketCapIcon from "../../assets/partner-coinmarketcap.png";
import CoinGeckoIcon from "../../assets/partner-coin-gecko.png";
import DexIcon from "../../assets/partner-dex.png";

//Community icon
import XIcon from "../../assets/x.png";
import TelegramIcon from "../../assets/telegram.png";
import DiscordIcon from "../../assets/discord.png";
import FlickrIcon from "../../assets/flickr.png";
import { useHistory } from "react-router-dom";

import { telegram, x, whitepaper, discord } from "../../contants/media";
import { collapseAddress } from "../../utils/utils";

const listFeatures = [
  {
    img: SwapIcon,
    title: "Swap",
    link: "/swap",
  },
  {
    img: StakingIcon,
    title: "Staking",
    link: "/staking",
  },
  {
    img: YeildIcon,
    title: "Yield Farm",
    link: "/yield-farm",
  },
];

const listPartners = [
  {
    img: MerlinIcon,
    name: "merlin",
    link: "",
    md: "3",
    xs: "6",
  },
  {
    img: AveIcon,
    name: "Ave.io",
    link: "",
    md: "3",
    xs: "6",
  },
  {
    img: CoinMarketCapIcon,
    name: "coinmarketcap",
    link: "",
    md: "3",
    xs: "6",
  },
  {
    img: CoinGeckoIcon,
    name: "coingecko",
    link: "",
    md: "3",
    xs: "6",
  },
  {
    img: DexIcon,
    name: "Dex screener",
    link: "",
    md: "3",
    xs: "6",
  },
];

const listCommunities = [
  {
    img: XIcon,
    name: "X",
    link: x,
  },
  {
    img: TelegramIcon,
    name: "Telegram",
    link: telegram,
  },
  {
    img: DiscordIcon,
    name: "Discord",
    link: discord,
  },
  // {
  //   img: FlickrIcon,
  //   name: "Flickr",
  //   link: "",
  // },
];

export default function Home() {
  const [, setIsLaunchApp] = useIsLaunchApp();
  const history = useHistory();
  const [userAddress] = useAddress();

  return (
    <>
      {/************* Intro section *************/}
      <Box>
        <Grid
          container
          px={5}
          justifyContent={"space-between"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"row-reverse"}
          className="home-content"
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
          >
            <div className="home-img-container">
              <img src={BroomCircle} alt="broomswap" />
              <img src={BroomStick} alt="broomswap" />
            </div>
          </Grid>
          <Grid item container xs={12} md={6} className="home-intro-content">
            <Typography className="title-broomswap">Broomswap</Typography>
            <Typography className="description-broomswap">
              BroomSwap is an innovative and highly capital efficient DEX built
              to support the Merlin ecosystem
            </Typography>
            <div className="home-group-btn">
              <CustomBtn
                text={"Whitepaper"}
                className={"white-border-btn"}
                variant={"outline"}
                onClick={() => window.open(whitepaper, "_blank")}
              />
              {userAddress ? (
                <CustomBtn
                  text={collapseAddress(userAddress)}
                  className={"golden-btn"}
                  variant={"contained"}
                  onClick={() => {}}
                />
              ) : (
                <CustomBtn
                  text={"Launch App"}
                  className={"golden-btn"}
                  variant={"contained"}
                  onClick={() => setIsLaunchApp(true)}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      {/************* List price coin section *************/}
      <CoinListPrice />

      {/************* Feature section *************/}
      <Box className="home-feature-container">
        <Box textAlign={"center"}>
          <Typography className="title">Broomswap Features</Typography>
          <Typography className="description">
            The best DEX build on Merlin
          </Typography>
        </Box>

        <Box className="home-feature-list">
          <Grid container justifyContent={"center"} display={"flex"}>
            {listFeatures.map((feature, index) => {
              return (
                <Grid key={index} className="home-feature-wrap">
                  <Grid
                    className="home-feature-item"
                    onClick={() => history.push(feature.link)}
                  >
                    <Grid className="home-feature-img">
                      <img src={feature.img} alt={feature.title} />
                    </Grid>
                    <Grid>
                      <Typography>{feature.title}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      {/*************** Partner section *************/}
      <Box className="home-partner-container">
        <Box textAlign={"center"}>
          <Typography className="title">Our Partners</Typography>
        </Box>
        <Box className="home-partner-list">
          <Grid container justifyContent={"center"}>
            {listPartners.map((partner, index) => {
              return (
                <Grid
                  key={index}
                  container
                  item
                  xs={partner.xs}
                  md={partner.md}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid className="home-partner-item">
                    <img src={partner.img} alt={partner.name} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      {/************* Community section *************/}
      <Box className="home-community-container">
        <Box textAlign={"center"}>
          <Typography className="title">Join Our Community</Typography>
        </Box>

        <Box className="home-community-list">
          <Grid container gap={"5px"} justifyContent={"center"}>
            {listCommunities.map((community, index) => {
              return (
                <Grid
                  key={index}
                  onClick={() => window.open(community.link, "_blank")}
                  className="home-community-item"
                  item
                  xs={5}
                  md={2.5}
                >
                  <img src={community.img} alt={community.name} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      {/************* Footer section *************/}
      <Footer />
    </>
  );
}
