import Web3 from "web3";
import { ethers } from "ethers";
import { A_DAY, A_MONTH, A_WEEK, A_YEAR } from "../contants/contract";
// import { getContractAbi } from "../api/common";

const web3 = new Web3(Web3.givenProvider);

function isJSONtype(data) {
  try {
    JSON.parse(data);
    return false;
  } catch {
    return true;
  }
}

async function getContractAbi(name) {
  var json = require(`../contractABI/${name}.json`);
  return json;
}

export function staking(address, contractAddress, contractName, amount, cb) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .deposit(ethers.utils.parseUnits(amount.toString(), 18), address)
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
      // if (result.success) {
      //   const abi = JSON.parse(result.message.result);
      //   const contract = new web3.eth.Contract(abi, contractAddress);
      //   contract.methods
      //     .deposit(address)
      //     .send({
      //       from: address,
      //       value: price.toString(),
      //     })
      //     .on("transactionHash", function () {
      //       cb(true);
      //     })
      //     .then((result) => {
      //       resolve(true);
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //       reject(false);
      //     });
      // }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function unstaking(address, contractAddress, contractName, amount, cb) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .withdraw(ethers.utils.parseUnits(amount.toString(), 18), address)
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function claimStaking(address, contractAddress, contractName, cb) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .harvest(address)
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function getTokenBalance(address, tokenAddress, tokenName = "erc20") {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(tokenName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, tokenAddress);
      const balance = await contract.methods.balanceOf(address).call();
      resolve(balance);
      // if (result.success) {
      //   const abi = JSON.parse(result.message.result);
      //   const contract = new web3.eth.Contract(abi, tokenAddress);
      //   const balance = await contract.methods.balanceOf(address).call();
      //   resolve(balance);
      // }
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function getRewardAmount(address, contractAddress, contractName) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const reward = await contract.methods.pendingReward(address).call();
      resolve(reward);
      // if (result.success) {
      //   const abi = JSON.parse(result.message.result);
      //   const contract = new web3.eth.Contract(abi, contractAddress);
      //   const reward = await contract.methods.pendingReward(address).call();
      //   resolve(reward);
      // }
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function getUserInfo(address, contractAddress, contractName) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const info = await contract.methods.userInfo(address).call();
      resolve(info);
      // if (result.success) {
      //   const abi = JSON.parse(result.message.result);
      //   const contract = new web3.eth.Contract(abi, contractAddress);
      //   const info = await contract.methods.userInfo(address).call();
      //   resolve(info);
      // }
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function getRewardAmountYield(
  address,
  contractAddress,
  contractName,
  poolId
) {
  return new Promise(async (resolve, reject) => {
    // console.log("aaaaaaaaa", address, contractAddress, contractName, poolId);
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const reward = await contract.methods
        .pendingReward(poolId.toString(16), address)
        .call();
      resolve(reward);
    } catch (e) {
      console.log(poolId, e);
      // reject(0);
    }
  });
}

export function getUserInfoYield(
  address,
  contractAddress,
  contractName,
  poolId
) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const info = await contract.methods
        .userInfo(poolId.toString(16), address)
        .call();
      resolve(info);
      // if (result.success) {
      //   const abi = JSON.parse(result.message.result);
      //   const contract = new web3.eth.Contract(abi, contractAddress);
      //   const info = await contract.methods.userInfo(address).call();
      //   resolve(info);
      // }
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function stakingYield(
  address,
  contractAddress,
  contractName,
  tokenAddress_1,
  amount_1,
  tokenAddress_2,
  amount_2,
  poolId,
  cb
) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .deposit(
          poolId.toString(16),
          tokenAddress_1,
          tokenAddress_2,
          ethers.utils.parseUnits(
            parseFloat(amount_1).toFixed(18).toString(),
            18
          ),
          ethers.utils.parseUnits(
            parseFloat(amount_2).toFixed(18).toString(),
            18
          ),
          address
        )
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function unstakingYield(
  address,
  contractAddress,
  contractName,
  amount_1,
  amount_2,
  poolId,
  cb
) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .withdraw(
          poolId.toString(16),
          ethers.utils.parseUnits(amount_1.toString(), 18),
          ethers.utils.parseUnits(amount_2.toString(), 18),
          address
        )
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function claimYield(address, contractAddress, contractName, poolId, cb) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi(contractName);
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      contract.methods
        .harvest(poolId.toString(16), address)
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function swap(
  address,
  contractAddress,
  tokenFrom,
  tokenTo,
  amountFrom,
  amountTo,
  maxSlippage,
  deadline,
  isBTC,
  fee,
  cb
) {
  console.log("isbtc: ", isBTC);
  return new Promise(async (resolve, reject) => {
    try {
      const path = ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [tokenFrom, fee, tokenTo]
      );
      const result = await getContractAbi("izumiswap");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const minAcquired = amountTo * (1 - maxSlippage / 100);
      const dl = deadline * 60 + Math.floor(new Date().getTime() / 1000);

      const itf = new ethers.utils.Interface(result);
      if (isBTC.from) {
        const dataSwap = itf.encodeFunctionData("swapAmount", [
          {
            path: path,
            recipient: address,
            amount: ethers.utils.parseUnits(
              parseFloat(amountFrom).toFixed(18).toString(),
              18
            ),
            minAcquired: ethers.utils.parseUnits(
              parseFloat(minAcquired).toFixed(18).toString(),
              18
            ),
            deadline: dl,
          },
        ]);
        console.log("btc from: ", dataSwap);

        contract.methods
          .multicall([dataSwap])
          .send({
            from: address,
            value: ethers.utils.parseUnits(
              parseFloat(amountFrom).toFixed(18).toString(),
              18
            ),
          })
          .on("transactionHash", function () {
            cb(true);
          })
          .then((result) => {
            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            reject(false);
          });
      } else if (isBTC.to) {
        const dataSwap = itf.encodeFunctionData("swapAmount", [
          {
            path: path,
            recipient: "0x0000000000000000000000000000000000000000",
            amount: ethers.utils.parseUnits(
              parseFloat(amountFrom).toFixed(18).toString(),
              18
            ),
            minAcquired: ethers.utils.parseUnits(
              parseFloat(minAcquired).toFixed(18).toString(),
              18
            ),
            deadline: dl,
          },
        ]);
        const dataUnwrap = itf.encodeFunctionData("unwrapWETH9", [0, address]);
        console.log("dataUnwrap: ", dataUnwrap);

        contract.methods
          .multicall([dataSwap, dataUnwrap])
          .send({
            from: address,
          })
          .on("transactionHash", function () {
            cb(true);
          })
          .then((result) => {
            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            reject(false);
          });
      } else {
        contract.methods
          .swapAmount([
            path,
            address,
            ethers.utils.parseUnits(
              parseFloat(amountFrom).toFixed(18).toString(),
              18
            ),
            0,
            // ethers.utils.parseUnits(
            //   parseFloat(minAcquired).toFixed(18).toString(),
            //   18
            // ),
            dl,
          ])
          .send({
            from: address,
          })
          .on("transactionHash", function () {
            cb(true);
          })
          .then((result) => {
            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            reject(false);
          });
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function limit(
  address,
  contractAddress,
  tokenFrom,
  tokenTo,
  amount,
  deadline,
  limitPrice,
  cb
) {
  return new Promise(async (resolve, reject) => {
    // console.log(
    //   address,
    //   contractAddress,
    //   tokenFrom,
    //   tokenTo,
    //   amount,
    //   deadline,
    //   limitPrice
    // );
    try {
      let dl = Math.floor(new Date().getTime() / 1000);
      switch (deadline) {
        case A_DAY:
          dl += 24 * 60 * 60;
          break;
        case A_WEEK:
          dl += 24 * 60 * 60 * 7;
          break;
        case A_MONTH:
          dl += 24 * 60 * 60 * 30;
          break;
        case A_YEAR:
          dl += 24 * 60 * 60 * 365;
          break;
        default:
          dl += 24 * 60 * 60;
          return;
      }
      const result = await getContractAbi("limit");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const order = await contract.methods
        .getActiveOrders(address, contractAddress)
        .call();
      contract.methods
        .newLimOrder(order, [
          tokenFrom,
          tokenTo,
          ethers.utils.parseUnits(amount.toString(), 18),
          dl,
        ])
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function getLimitOrder(address, contractAddress) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi("limit");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const order = await contract.methods
        .getActiveOrders(address, contractAddress)
        .call();
      resolve(order);
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function checkAllowanceToken(address, contractAddress, tokenAddress) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi("erc20");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, tokenAddress);
      const allowance = await contract.methods
        .allowance(address, contractAddress)
        .call();
      resolve(allowance);
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function approveToken(address, contractAddress, tokenAddress, cb) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi("erc20");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, tokenAddress);
      contract.methods
        .approve(
          contractAddress,
          ethers.utils.parseUnits((1000000000).toString(), 18)
        )
        .send({
          from: address,
        })
        .on("transactionHash", function () {
          cb(true);
        })
        .then((result) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export function fetchPriceToken(contractAddress, tokenFrom, tokenTo, fee) {
  const amount = 1;
  return new Promise(async (resolve, reject) => {
    try {
      const path = ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [tokenFrom, fee, tokenTo]
      );
      const result = await getContractAbi("quote");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const price = await contract.methods
        .swapAmount(ethers.utils.parseUnits(amount.toString(), 18), path)
        .call();
      console.log("fetchPriceToken", tokenFrom, tokenTo, price);
      resolve(price);
    } catch (e) {
      console.log("fetchPriceToken", tokenFrom, tokenTo, e);
      // reject(0);
      resolve(0);
    }
  });
}

export function getTimeStaking(address, contractAddress) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi("staking");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const info = await contract.methods.getUserInfo(address).call();
      resolve(info);
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}

export function getTimeYield(address, contractAddress, poolId) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await getContractAbi("yield");
      const abi = isJSONtype(result) ? result : JSON.parse(result);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const info = await contract.methods
        .getUserInfo(poolId.toString(16), address)
        .call();
      resolve(info);
    } catch (e) {
      console.log(e);
      reject(0);
    }
  });
}
