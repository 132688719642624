import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import "./swap.css";
import { useEffect, useState } from "react";
import TokenSwap from "../../components/TokenSwap";
import IconSwap from "../../assets/round-swap.svg";
import IconBTC from "../../assets/btc-icon.png";
import CustomBtn from "../../components/CustomBtn";
import SelectTokenDialog from "../../components/SelectTokenDialog";
import Slippage from "../../components/Slippage";
import TokenLimit from "../../components/TokenLimit";
import {
  useAddress,
  useBalance,
  useListPool,
  useListTokenPrice,
  useOpenLoading,
  useOpenNotiBar,
} from "../../contexts/common";
import {
  approveToken,
  checkAllowanceToken,
  fetchPriceToken,
  getPath,
  getTokenBalance,
  limit,
  swap,
} from "../../services/smartcontract";
import {
  A_DAY,
  A_MONTH,
  A_WEEK,
  A_YEAR,
  DECIMAL_18,
} from "../../contants/contract";
import { checkEnoughBalance } from "../../utils/utils";
import ComingSoon from "../ComingSoon";
import { getBalanceWallet } from "../../utils/connectMetamask";

function TabSwap(props) {
  const {
    tokenFrom,
    tokenTo,
    openSelectTokenBox,
    setIsChoosingFrom,
    swap2Token,
    swapAmount,
    setSwapAmount,
    onSwap,
    enableSwap,
    exchangeRate,
  } = props;

  function handleSetSwapAmount(amount, type) {
    swapAmount[type] = amount;
    if (type === "from" && tokenTo.name) {
      swapAmount["to"] = amount * exchangeRate;
    } else if (type === "to" && tokenFrom.name) {
      swapAmount["from"] = amount / exchangeRate;
    }
    setSwapAmount({ ...swapAmount });
  }

  return (
    <Box className="tabswap-container">
      <Box className="tabswap-box">
        <TokenSwap
          whereTitle={"From"}
          price={tokenFrom?.price}
          priceDolar={tokenFrom?.priceDolar}
          balance={tokenFrom?.balance}
          tokenName={tokenFrom?.symbol}
          tokenLogo={tokenFrom?.icon}
          openSelectTokenBox={() => {
            openSelectTokenBox(true);
            setIsChoosingFrom(true);
          }}
          swapAmount={swapAmount.from}
          setSwapAmount={handleSetSwapAmount}
          usdRate={tokenFrom.usd}
        />
        <TokenSwap
          whereTitle={"To"}
          price={tokenTo?.price}
          priceDolar={tokenTo?.priceDolar}
          balance={tokenTo?.balance}
          tokenName={tokenTo?.symbol}
          tokenLogo={tokenTo?.icon}
          openSelectTokenBox={() => {
            openSelectTokenBox(true);
            setIsChoosingFrom(false);
          }}
          swapAmount={swapAmount.to}
          setSwapAmount={handleSetSwapAmount}
          usdRate={tokenTo.usd}
        />
        <img
          src={IconSwap}
          alt="icon swap"
          className="icon-swap"
          onClick={swap2Token}
        />
      </Box>

      {tokenFrom?.symbol && tokenTo?.symbol && (
        <Box className="price-exchange">
          <Typography>
            1 {tokenFrom.symbol} = {tokenFrom.usd / tokenTo.usd}{" "}
            {tokenTo.symbol} <span>(${tokenFrom.usd})</span>
          </Typography>
        </Box>
      )}

      <CustomBtn
        text={"Swap"}
        className={"orange-btn full-width"}
        variant={"contained"}
        disabled={!tokenFrom.name || !tokenTo.name || enableSwap}
        onClick={() => onSwap(false)}
      />
    </Box>
  );
}

function TabLimit(props) {
  const {
    tokenFrom,
    tokenTo,
    openSelectTokenBox,
    setIsChoosingFrom,
    swap2Token,
    swapAmount,
    setSwapAmount,
    onSwap,
    timeOption,
    setTimeOption,
    limitPrice,
    setLimitPrice,
    enableSwap,
    exchangeRate,
  } = props;

  const timeOptionList = [
    {
      label: "1 day",
      value: A_DAY,
    },
    {
      label: "1 week",
      value: A_WEEK,
    },
    {
      label: "1 month",
      value: A_MONTH,
    },
    {
      label: "1 year",
      value: A_YEAR,
    },
  ];

  function handleSetSwapAmount(amount) {
    swapAmount["from"] = parseInt(amount);
    swapAmount["to"] = parseInt(amount) * limitPrice;
    setSwapAmount({ ...swapAmount });
  }

  return (
    <Box className="wrap-comming-soon">
      <ComingSoon />
    </Box>
  );
  // return (
  //   <Box className="tabswap-container">
  //     <Box className="tabswap-box">
  //       <TokenLimit
  //         tokenFromName={tokenFrom?.symbol}
  //         tokenFromLogo={tokenFrom?.icon}
  //         tokenToName={tokenTo?.symbol}
  //         tokenToLogo={tokenTo?.icon}
  //         limitPrice={limitPrice}
  //         setLimitPrice={setLimitPrice}
  //         swap2Token={swap2Token}
  //       />
  //     </Box>

  //     <Box className="tabswap-box">
  //       <TokenSwap
  //         whereTitle={"Sell"}
  //         price={tokenFrom?.price}
  //         priceDolar={tokenFrom?.priceDolar}
  //         balance={tokenFrom?.balance}
  //         tokenName={tokenFrom?.symbol}
  //         tokenLogo={tokenFrom?.icon}
  //         openSelectTokenBox={() => {
  //           openSelectTokenBox(true);
  //           setIsChoosingFrom(true);
  //         }}
  //         swapAmount={swapAmount.from}
  //         setSwapAmount={handleSetSwapAmount}
  //         usdRate={tokenFrom.usd}
  //       />
  //       <TokenSwap
  //         whereTitle={"Buy"}
  //         price={tokenTo?.price}
  //         priceDolar={tokenTo?.priceDolar}
  //         balance={tokenTo?.balance}
  //         tokenName={tokenTo?.symbol}
  //         tokenLogo={tokenTo?.icon}
  //         openSelectTokenBox={() => {
  //           openSelectTokenBox(true);
  //           setIsChoosingFrom(false);
  //         }}
  //         swapAmount={swapAmount.to}
  //         setSwapAmount={() => {}}
  //         usdRate={tokenTo.usd}
  //       />
  //       <img
  //         src={IconSwap}
  //         alt="icon swap"
  //         className="icon-swap"
  //         onClick={swap2Token}
  //       />
  //     </Box>

  //     <Box className="expiry-box">
  //       <Typography className="expiry-title">Expiry</Typography>
  //       <Grid className="expiry-options">
  //         {timeOptionList.map((option, index) => {
  //           return (
  //             <Typography
  //               key={index}
  //               className={`time-option ${
  //                 timeOption === option.value ? "chosen-time" : ""
  //               }`}
  //               onClick={() => setTimeOption(option.value)}
  //             >
  //               {option.label}
  //             </Typography>
  //           );
  //         })}
  //       </Grid>
  //     </Box>
  //     <CustomBtn
  //       text={"Swap"}
  //       className={"orange-btn full-width"}
  //       variant={"contained"}
  //       onClick={() => onSwap(true)}
  //       disabled={!tokenFrom.name || !tokenTo.name || enableSwap}
  //     />
  //   </Box>
  // );
}

export default function Swap() {
  const [, setOpenLoading] = useOpenLoading();
  const [, setOpenNotiBar] = useOpenNotiBar();
  const [listPool] = useListPool();
  const [userAddress] = useAddress();
  const [nativeBalance, setNativeBalance] = useBalance();
  const [currentTab, setCurrentTab] = useState(0);
  const [openSelectTokenBox, setOpenSelectTokenBox] = useState(false);
  const [selectedToken, setSelectedToken] = useState({
    from: {},
    to: {},
  });
  const [isChoosingFrom, setIsChoosingFrom] = useState(true);
  const [maxSlippage, setMaxSlippage] = useState(0.5);
  const [transactionDeadline, setTransactionDeadline] = useState(0.5);
  const [isAuto, setIsAuto] = useState(true);
  const [timeOption, setTimeOption] = useState(A_DAY);
  const [limitPrice, setLimitPrice] = useState(0);
  const [swapAmount, setSwapAmount] = useState({
    from: 0,
    to: 0,
  });
  const [listTokenPrice] = useListTokenPrice();
  const [isBTC, setIsBTC] = useState({
    from: false,
    to: false,
  });
  const [enableSwap, setEnableSwap] = useState(true);
  const [currentPool, setCurrentPool] = useState();
  const [exchangeRate, setExchangeRate] = useState(1);

  function handleChangeTab(event, newValue) {
    setCurrentTab(newValue);
    setCurrentPool();
    setEnableSwap(false);
    setExchangeRate(1);
    setSwapAmount({
      from: 0,
      to: 0,
    });
    setSelectedToken({
      from: {},
      to: {},
    });
    setIsBTC(false);
    setLimitPrice(0);
  }

  async function handleSetToken(token) {
    // console.log(token);
    if (token && token.name) {
      //call contract get balance and usdExchangeRate
      let balance = 0;
      if (userAddress) {
        if (token.symbol === "BTC") {
          balance = nativeBalance;
        } else {
          balance = await getTokenBalance(
            userAddress,
            token.contracts[token.chains[0]].address,
            "erc20"
          );
        }
      }
      token.balance = balance;
      const tokenPrice = listTokenPrice[token.symbol];
      token.usd = tokenPrice ? tokenPrice : 0;

      // if (token.name === "BTC") setIsBTC(false); //set true moi dung
      // else setIsBTC(false);

      if (
        selectedToken.from?.name &&
        selectedToken.to?.name &&
        currentTab === 0
      ) {
        handleFetchPrice(currentPool?.fee);
        // fetchPriceToken(
        //   process.env.REACT_APP_QUOTE_ADDRESS,
        //   selectedToken.from.contracts[selectedToken.from.chains[0]].address,
        //   selectedToken.from.contracts[selectedToken.to.chains[0]].address,
        //   currentPool?.fee
        // );
      }

      if (isChoosingFrom) {
        selectedToken.from = token;
        if (token.symbol === "BTC") setIsBTC({ ...isBTC, from: true });
        else setIsBTC({ ...isBTC, from: false });
      } else {
        selectedToken.to = token;
        if (token.symbol === "BTC") setIsBTC({ ...isBTC, to: true });
        else setIsBTC({ ...isBTC, to: false });
      }

      // if (selectedToken.from.name && selectedToken.to.name)
      //   setLimitPrice(selectedToken.from.usd / selectedToken.to.usd);

      // if (token.name === "BTC") setIsBTC(true); //set true moi dung
      // else setIsBTC(false);

      // if (exchangeRate === 0) {
      //   setEnableSwap(false);
      // }
      // setEnableSwap(true);
      setSelectedToken({ ...selectedToken });
    }
  }

  function swap2Token() {
    const tokenTmp = { ...selectedToken.from };
    selectedToken.from = { ...selectedToken.to };
    selectedToken.to = { ...tokenTmp };

    const amountTmp = swapAmount.from;
    swapAmount.from = swapAmount.to;
    swapAmount.to = amountTmp;

    if (selectedToken.from.symbol === "BTC")
      setIsBTC({ from: true, to: false });
    else if (selectedToken.to.symbol === "BTC")
      setIsBTC({ to: true, from: false });

    setSelectedToken({ ...selectedToken });
    setSwapAmount({ ...swapAmount });
  }

  async function isAllowance(contractAddress, tokenAddress) {
    const allowance = await checkAllowanceToken(
      userAddress,
      contractAddress,
      tokenAddress
    );
    if (allowance > 0) {
      return;
    } else {
      await approveToken(userAddress, contractAddress, tokenAddress, () =>
        setOpenLoading(true)
      );
    }
  }

  async function actionSuccessfully() {
    // 1. clear amount from and to
    setSwapAmount({ from: 0, to: 0 });
    let values = [];
    // 2. get balance 2 token swap
    if (selectedToken.from.symbol === "BTC") {
      values = await Promise.all([
        getBalanceWallet(userAddress),
        getTokenBalance(
          userAddress,
          selectedToken.to.contracts[selectedToken.to.chains[0]].address,
          "erc20"
        ),
      ]);
      setNativeBalance(values[0]);
    } else if (selectedToken.to.symbol === "BTC") {
      values = await Promise.all([
        getTokenBalance(
          userAddress,
          selectedToken.from.contracts[selectedToken.from.chains[0]].address,
          "erc20"
        ),
        getBalanceWallet(userAddress),
      ]);
      setNativeBalance(values[1]);
    } else {
      values = await Promise.all([
        getTokenBalance(
          userAddress,
          selectedToken.from.contracts[selectedToken.from.chains[0]].address,
          "erc20"
        ),
        getTokenBalance(
          userAddress,
          selectedToken.to.contracts[selectedToken.to.chains[0]].address,
          "erc20"
        ),
      ]);
    }

    selectedToken.from.balance = values[0];
    selectedToken.to.balance = values[1];
    setSelectedToken({ ...selectedToken });
  }

  async function handleSwap() {
    const checkEnough = checkEnoughBalance(
      swapAmount.from,
      selectedToken.from?.balance / DECIMAL_18,
      () =>
        setOpenNotiBar({
          isOpen: true,
          message: "Insufficient balance!",
          type: "error",
        })
    );
    if (!checkEnough) return;

    setOpenLoading(true);
    if (!isBTC.from) {
      await isAllowance(
        process.env.REACT_APP_MERLIN_SWAP_ADDRESS,
        selectedToken.from.contracts[selectedToken.from.chains[0]].address
      );
    }

    swap(
      userAddress,
      process.env.REACT_APP_MERLIN_SWAP_ADDRESS,
      selectedToken.from.contracts[selectedToken.from.chains[0]].address,
      selectedToken.to.contracts[selectedToken.to.chains[0]].address,
      swapAmount.from,
      swapAmount.to,
      maxSlippage,
      transactionDeadline,
      isBTC,
      currentPool?.fee ? currentPool?.fee : 2000,
      () => setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        actionSuccessfully();
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  async function handleLimit() {
    const checkEnough = checkEnoughBalance(
      swapAmount.from,
      selectedToken.from?.balance,
      () =>
        setOpenNotiBar({
          isOpen: true,
          message: "Insufficient balance!",
          type: "error",
        })
    );
    if (!checkEnough) return;
    setOpenLoading(true);
    await isAllowance(
      process.env.REACT_APP_MERLIN_LIMIT_ADDRESS,
      selectedToken.from.contracts[selectedToken.from.chains[0]].address
    );

    limit(
      userAddress,
      process.env.REACT_APP_MERLIN_LIMIT_ADDRESS,
      selectedToken.from.contracts[selectedToken.from.chains[0]].address,
      selectedToken.to.contracts[selectedToken.to.chains[0]].address,
      swapAmount.from,
      timeOption,
      limitPrice,
      () => setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function checkExistPool(addressFrom, addressTo) {
    let res = false;
    listPool.map((pool) => {
      if (
        (pool.tokenX_address.toLowerCase() === addressFrom.toLowerCase() &&
          pool.tokenY_address.toLowerCase() === addressTo.toLowerCase()) ||
        (pool.tokenY_address.toLowerCase() === addressFrom.toLowerCase() &&
          pool.tokenX_address.toLowerCase() === addressTo.toLowerCase())
      ) {
        res = true;
        handleFetchPrice(pool.fee);
        setCurrentPool(pool);
        setEnableSwap(true);
      }
    });
    if (!res)
      setOpenNotiBar({
        isOpen: true,
        message: "There is no pool for this pairs",
        type: "error",
      });
    return res;
  }

  async function handleFetchPrice(fee) {
    const price = await fetchPriceToken(
      process.env.REACT_APP_QUOTE_ADDRESS,
      selectedToken.from.contracts[selectedToken.from.chains[0]].address,
      selectedToken.to.contracts[selectedToken.to.chains[0]].address,
      fee
    );
    const exchangeRate = price.acquire / DECIMAL_18;
    setExchangeRate(exchangeRate);

    if (isChoosingFrom) {
      swapAmount.from = swapAmount.to / exchangeRate;
    } else swapAmount.to = swapAmount.from * exchangeRate;

    setSwapAmount({ ...swapAmount });
  }

  useEffect(() => {
    if (selectedToken.from.name && selectedToken.to.name && currentTab === 0) {
      const isExist = checkExistPool(
        selectedToken.from.contracts[selectedToken.from.chains[0]].address,
        selectedToken.to.contracts[selectedToken.to.chains[0]].address
      );
      if (!isExist) setEnableSwap(true);
      else setEnableSwap(false);
    }
  }, [selectedToken.from.name, selectedToken.to.name, currentTab]);

  useEffect(() => {
    if (!selectedToken.from.name) setSwapAmount({ ...swapAmount, from: 0 });
  }, [selectedToken.from.name]);

  useEffect(() => {
    if (!selectedToken.to.name) setSwapAmount({ ...swapAmount, to: 0 });
  }, [selectedToken.to.name]);

  useEffect(() => {
    swapAmount.to = swapAmount.from * limitPrice;
    setSwapAmount({ ...swapAmount });
  }, [limitPrice]);

  return (
    <Box className="swap-container">
      <SelectTokenDialog
        open={openSelectTokenBox}
        setOpen={setOpenSelectTokenBox}
        selectedValue={selectedToken}
        setSelectedValue={handleSetToken}
      />
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
        className="tabs-container"
      >
        <Tab label="Swap" value={0} className="tab-label" />
        <Tab label="Limit" value={1} className="tab-label" />
        {currentTab === 0 ? (
          <Slippage
            maxSlippage={maxSlippage}
            transactionDeadline={transactionDeadline}
            setMax={setMaxSlippage}
            setDeadline={setTransactionDeadline}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
          />
        ) : (
          ""
        )}
      </Tabs>

      <Box>
        {currentTab === 0 ? (
          <TabSwap
            tokenFrom={selectedToken.from}
            tokenTo={selectedToken.to}
            openSelectTokenBox={setOpenSelectTokenBox}
            setIsChoosingFrom={setIsChoosingFrom}
            swap2Token={swap2Token}
            swapAmount={swapAmount}
            setSwapAmount={setSwapAmount}
            onSwap={handleSwap}
            enableSwap={enableSwap}
            exchangeRate={exchangeRate}
          />
        ) : (
          <TabLimit
            tokenFrom={selectedToken.from}
            tokenTo={selectedToken.to}
            openSelectTokenBox={setOpenSelectTokenBox}
            setIsChoosingFrom={setIsChoosingFrom}
            swap2Token={swap2Token}
            swapAmount={swapAmount}
            setSwapAmount={setSwapAmount}
            onSwap={handleLimit}
            timeOption={timeOption}
            setTimeOption={setTimeOption}
            limitPrice={limitPrice}
            setLimitPrice={setLimitPrice}
            enableSwap={enableSwap}
            exchangeRate={exchangeRate}
          />
        )}
      </Box>
    </Box>
  );
}
