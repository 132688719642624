import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useReducer,
} from "react";

const ADDRESS = "ADDRESS";
const NETWORK = "NETWORK";
const BALANCE = "BALANCE";
const IS_LAUNCH_APP = "IS_LAUNCH_APP";
const CONTRACT_ABI = "CONTRACT_ABI";
const OPEN_NOTI_BAR = "OPEN_NOTI_BAR";
const OPEN_LOADING = "OPEN_LOADING";
const LIST_TOKEN_PRICE = "LIST_TOKEN_PRICE";
const LIST_POOL = "LIST_POOL";
const Context = createContext();

function useCommonContext() {
  return useContext(Context);
}

const initState = {
  isLaunchApp: false,
  address: "",
  network: "",
  balance: 0,
  contractABI: {},
  openNotiBar: {
    isOpen: false,
    message: "",
    type: "success",
  },
  openLoading: false,
  listTokenPrice: null,
  listPool: [],
};

function reducer(state, { type, payload }) {
  switch (type) {
    case IS_LAUNCH_APP: {
      const { isLaunchApp } = payload;
      return {
        ...state,
        isLaunchApp: isLaunchApp,
      };
    }
    case ADDRESS: {
      const { address } = payload;
      return {
        ...state,
        address: address,
      };
    }
    case NETWORK: {
      const { network } = payload;
      return {
        ...state,
        network: network,
      };
    }
    case CONTRACT_ABI: {
      const { contractABI } = payload;
      return {
        ...state,
        contractABI: { ...state.contractABI, contractABI },
      };
    }
    case OPEN_NOTI_BAR: {
      const { openNotiBar } = payload;
      return {
        ...state,
        openNotiBar: { ...openNotiBar },
      };
    }
    case OPEN_LOADING: {
      const { openLoading } = payload;
      return {
        ...state,
        openLoading: openLoading,
      };
    }
    case LIST_TOKEN_PRICE: {
      const { listTokenPrice } = payload;
      return {
        ...state,
        listTokenPrice: listTokenPrice,
      };
    }
    case LIST_POOL: {
      const { listPool } = payload;
      return {
        ...state,
        listPool: listPool,
      };
    }
    case BALANCE: {
      const { balance } = payload;
      return {
        ...state,
        balance: balance,
      };
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`);
    }
  }
}
export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);

  const updateIsLaunchApp = useCallback((isLaunchApp) => {
    dispatch({
      type: IS_LAUNCH_APP,
      payload: {
        isLaunchApp,
      },
    });
  }, []);

  const updateAddress = useCallback((address) => {
    dispatch({
      type: ADDRESS,
      payload: {
        address,
      },
    });
  }, []);

  const updateNetwork = useCallback((network) => {
    dispatch({
      type: NETWORK,
      payload: {
        network,
      },
    });
  }, []);

  const updateContractABI = useCallback((contractABI) => {
    dispatch({
      type: CONTRACT_ABI,
      payload: {
        contractABI,
      },
    });
  }, []);

  const updateOpenNotiBar = useCallback((openNotiBar) => {
    dispatch({
      type: OPEN_NOTI_BAR,
      payload: {
        openNotiBar,
      },
    });
  }, []);

  const updateOpenLoading = useCallback((openLoading) => {
    dispatch({
      type: OPEN_LOADING,
      payload: {
        openLoading,
      },
    });
  }, []);

  const updateListTokenPrice = useCallback((listTokenPrice) => {
    dispatch({
      type: LIST_TOKEN_PRICE,
      payload: {
        listTokenPrice,
      },
    });
  }, []);

  const updateListPool = useCallback((listPool) => {
    dispatch({
      type: LIST_POOL,
      payload: {
        listPool,
      },
    });
  }, []);

  const updateBalance = useCallback((balance) => {
    dispatch({
      type: BALANCE,
      payload: {
        balance,
      },
    });
  }, []);

  return (
    <Context.Provider
      value={useMemo(
        () => [
          state,
          {
            updateIsLaunchApp,
            updateAddress,
            updateNetwork,
            updateContractABI,
            updateOpenNotiBar,
            updateOpenLoading,
            updateListTokenPrice,
            updateListPool,
            updateBalance,
          },
        ],
        [
          state,
          updateIsLaunchApp,
          updateAddress,
          updateNetwork,
          updateContractABI,
          updateOpenNotiBar,
          updateOpenLoading,
          updateListTokenPrice,
          updateListPool,
          updateBalance,
        ]
      )}
    >
      {children}
    </Context.Provider>
  );
}

export function useIsLaunchApp() {
  const [state, { updateIsLaunchApp }] = useCommonContext();
  return [state.isLaunchApp, updateIsLaunchApp];
}

export function useAddress() {
  const [state, { updateAddress }] = useCommonContext();
  return [state.address, updateAddress];
}

export function useNetwork() {
  const [state, { updateNetwork }] = useCommonContext();
  return [state.network, updateNetwork];
}

export function useContractABI() {
  const [state, { updateContractABI }] = useCommonContext();
  return [state.contractABI, updateContractABI];
}

export function useOpenNotiBar() {
  const [state, { updateOpenNotiBar }] = useCommonContext();
  return [state.openNotiBar, updateOpenNotiBar];
}

export function useOpenLoading() {
  const [state, { updateOpenLoading }] = useCommonContext();
  return [state.openLoading, updateOpenLoading];
}

export function useListTokenPrice() {
  const [state, { updateListTokenPrice }] = useCommonContext();
  return [state.listTokenPrice, updateListTokenPrice];
}

export function useListPool() {
  const [state, { updateListPool }] = useCommonContext();
  return [state.listPool, updateListPool];
}

export function useBalance() {
  const [state, { updateBalance }] = useCommonContext();
  return [state.balance, updateBalance];
}
