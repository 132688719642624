import axios from "axios";
import { API, API_Merlin, standardResponse } from "./middleware";
import { contract_abi_url } from "../contants/contract";

export function getContractAbi(address) {
  const url = contract_abi_url + address;
  return axios
    .get(url)
    .then((response) => {
      return {
        success: true,
        message: response.data,
      };
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response?.data,
      };
    });
}

export async function getListToken() {
  return API.get("/api/v1/homepage/prices")
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

export async function getStakingInfo() {
  return API.get("/api/v1/staking")
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

export async function getYieldFarmInfo() {
  return API.get("/api/v1/yield")
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

///////////////////////////////////////////////////

export async function getListTokenSwap() {
  return API_Merlin.get(process.env.REACT_APP_MERLIN_LIST_TOKEN_SWAP)
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

export async function getListTokenPrice() {
  return API_Merlin.get(process.env.REACT_APP_MERLIN_LIST_TOKEN_PRICE)
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

export async function getListPool() {
  return API_Merlin.get(process.env.REACT_APP_MERLIN_LIST_POOL)
    .then((response) => {
      return standardResponse(true, response.data);
    })
    .catch((error) => {
      return standardResponse(false, error.response?.data);
    });
}

// export async function getTokenPrice() {
//   return API.get("/api/v1/homepage/prices")
//     .then((response) => {
//       return standardResponse(true, response.data);
//     })
//     .catch((error) => {
//       return standardResponse(false, error.response?.data);
//     });
// }
