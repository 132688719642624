import { useState, useEffect, useRef } from "react";
import {
  Box,
  DialogTitle,
  Dialog,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import "./styles.css";
import CloseIcon from "@mui/icons-material/Close";
import { useAddress } from "../../contexts/common";
import WarningIcon from "../../assets/warning-icon.svg";
import CustomBtn from "../CustomBtn";
import { getTimeStaking, getTimeYield } from "../../services/smartcontract";
import { SEVEN_DAY } from "../../contants/contract";

function SimpleDialog(props) {
  const { onClose, selectedValue, open, fn, remainTime } = props;
  const [remainTimeDisplay, setRemainTimeDisplay] = useState("00D:00H:00M:00S");
  const [userAddress] = useAddress();

  const handleClose = () => {
    onClose(selectedValue);
  };

  useEffect(() => {
    if (remainTime) {
      let interval = setInterval(() => {
        const formatTime = countdownTime(remainTime);
        setRemainTimeDisplay(formatTime);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [remainTime]);

  function countdownTime() {
    const timestamp =
      remainTime + SEVEN_DAY - Math.floor(new Date().getTime() / 1000);
    if (timestamp < 0) return `00D:00H:00M:00S`;

    let day = Math.floor(timestamp / (24 * 3600));
    let hour = Math.floor(timestamp / 3600) % 24;
    let min = Math.floor(timestamp / 60) % 60;
    let sec = timestamp % 60;

    hour = hour < 10 ? "0" + hour : hour;
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;

    return `0${day}D:${hour}H:${min}M:${sec}S`;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="penalty-dialog-container"
    >
      <DialogTitle>
        <Box>
          <img src={WarningIcon} alt="warning icon" />
          <Typography>Warning</Typography>
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#D6CFC3",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box>
        <Grid container className="penalty-dialog-content">
          <Typography className="penalty-dialog-description">
            If you withdraw within the first 7 days, you will only receive 70%
            of the deposit
          </Typography>
          <Box className="penalty-dialog-remain-box">
            <Typography className="label">Remaining penalty time</Typography>
            <Typography className="value">{remainTimeDisplay}</Typography>
          </Box>
          <CustomBtn
            text={"Unstake"}
            className={"orange-btn full-width"}
            variant={"contained"}
            onClick={() => {
              fn();
              handleClose();
            }}
          />
        </Grid>
      </Box>
    </Dialog>
  );
}

export default function PenaltyDialog(props) {
  const { open, setOpen, unstake, remainTime } = props;
  const [selectedValue, setSelectedValue] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        fn={unstake}
        remainTime={remainTime}
      />
    </>
  );
}
