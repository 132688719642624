import { Box, Drawer, Grid, Typography } from "@mui/material";
import "./menu.css";
import CustomBtn from "../CustomBtn";
import Logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";
import { useAddress, useIsLaunchApp } from "../../contexts/common";
import { useEffect, useState } from "react";
import { collapseAddress } from "../../utils/utils";
import MenuIcon from "../../assets/menu-icon.png";
import { IconButton } from "@mui/material";
import MenuList from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import WalletDialog from "../WalletDialog";

const listMenu = [
  {
    title: "Swap",
    link: "/swap",
  },
  // {
  //   title: "Pool",
  //   link: "/pool",
  // },
  {
    title: "Staking",
    link: "/staking",
  },
  {
    title: "Yield Farm",
    link: "/yield-farm",
  },
  {
    title: "Airdrops",
    link: "/airdrops",
  },
];
export default function Menu() {
  const history = useHistory();
  const [isLaunchApp, setIsLaunchApp] = useIsLaunchApp();
  const [address, setAddress] = useAddress();
  const [openWallet, setOpenWallet] = useState(false);
  const pathArray = window.location.pathname.split("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isLaunchApp) {
      setOpenWallet(true);
      setIsLaunchApp(false);
    }
    // eslint-disable-next-line
  }, [isLaunchApp]);

  async function connectUnisatWallet() {
    if (typeof window.unisat === "undefined") {
      console.log("UniSat Wallet is not installed!");
    } else {
      try {
        let accounts = await window.unisat.requestAccounts();
        // console.log("connect success", accounts);
        // console.log(collapseAddress(accounts[0]));
        setAddress(accounts[0]);
      } catch (e) {
        console.log("connect failed");
      }
    }
    //step 1. check install app
    //step 2. check connect
    //step 3. check network
    //step 3.1 if wrong network -> switch
    //step 4. get address
  }

  function isActiveLink(link) {
    return link === "/" + pathArray[1];
  }

  return (
    <Box className="menu-container">
      <WalletDialog open={openWallet} setOpen={setOpenWallet} />
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="menu-content"
      >
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="menu-bar"
        >
          <img
            src={Logo}
            alt="logo"
            onClick={() => history.push("/")}
            style={{ cursor: "pointer", width: "128px" }}
          />
          <Grid className="menu-link-container menu-desktop">
            {listMenu.map((menu, index) => {
              return (
                <Typography
                  key={index}
                  className={`menu-link ${
                    isActiveLink(menu.link) ? "menu-active" : ""
                  }`}
                  onClick={() => history.push(menu.link)}
                >
                  {menu.title}
                </Typography>
              );
            })}
          </Grid>

          {/*********** Menu mobile *********/}
          <>
            <Box className="menuMobileContainer">
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="mobile-menu-icon"
              >
                <img src={MenuIcon} alt="menu icon" />
              </IconButton>
            </Box>
            <MenuList
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="menu-list"
            >
              {listMenu.map((menu, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push(menu.link);
                    }}
                    className={`menu-link ${
                      isActiveLink(menu.link) ? "menu-active" : ""
                    }`}
                    key={index}
                  >
                    {menu.title}
                  </MenuItem>
                );
              })}
              <CustomBtn
                text={address ? collapseAddress(address) : "Launch App"}
                className={"golden-btn"}
                variant={"contained"}
                onClick={() => {
                  if (address === "") setIsLaunchApp(true);
                }}
              />
            </MenuList>
          </>
          {/*********** End Menu mobile *********/}
        </Grid>
        <Grid className="menu-desktop">
          <CustomBtn
            text={address ? collapseAddress(address) : "Launch App"}
            className={"golden-btn"}
            variant={"contained"}
            onClick={() => {
              if (address === "") setIsLaunchApp(true);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
