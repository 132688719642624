import { Box, Grid, Menu, MenuList, Typography } from "@mui/material";
import "./slippage.css";
import SettingIcon from "../../assets/setting.svg";
import { useState } from "react";
import { sliceNumber1Dec } from "../../utils/utils";

export default function Slippage(props) {
  const {
    maxSlippage,
    transactionDeadline,
    setMax,
    setDeadline,
    isAuto,
    setIsAuto,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className="slippage-box"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography>{`${
          maxSlippage ? maxSlippage : 0.5
        }% Slippage`}</Typography>
        <img src={SettingIcon} alt="setting icon" />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="slippage-content"
      >
        <Box className="setting-box">
          <Grid className="content-box">
            <Typography className="content-box-title">Max. slippage</Typography>
            <Grid className="content-box-mode" container>
              <Grid className="setting-mode" xs={5.5} item>
                <Typography
                  className={`mode-option ${isAuto ? "chosen-mode" : ""}`}
                  onClick={() => {
                    setIsAuto(true);
                    setMax(0.5);
                    setDeadline(0.5);
                  }}
                >
                  Auto
                </Typography>
                <Typography
                  className={`mode-option ${!isAuto ? "chosen-mode" : ""}`}
                  onClick={() => setIsAuto(false)}
                >
                  Custom
                </Typography>
              </Grid>
              <Grid className="input-box" xs={5.5} item>
                <input
                  type="number"
                  onChange={(e) => {
                    if (e.target.value < 0) setMax(0);
                    else setMax(sliceNumber1Dec(e.target.value));
                  }}
                  value={maxSlippage}
                  disabled={isAuto}
                />
                <Typography>%</Typography>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider-line"></div>
          <Grid className="content-box">
            <Typography className="content-box-title">
              Transaction deadline
            </Typography>
            <Grid>
              <Grid className="input-box">
                <input
                  type="number"
                  onChange={(e) => {
                    if (e.target.value < 0) setDeadline(0);
                    else setDeadline(sliceNumber1Dec(e.target.value));
                  }}
                  value={transactionDeadline}
                  disabled={isAuto}
                />
                <Typography>Min</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
}
