import { Box, Grid, Typography } from "@mui/material";
import "./styles.css";
import ArrowDown from "../../assets/down-gray.svg";
import { DECIMAL_18 } from "../../contants/contract";
import { sliceNumber2Dec, sliceNumber6Dec } from "../../utils/utils";

export default function TokenSwap(props) {
  const {
    whereTitle,
    balance,
    tokenName,
    tokenLogo,
    openSelectTokenBox,
    swapAmount,
    setSwapAmount,
    usdRate,
  } = props;

  function convertToDolar() {
    return swapAmount * usdRate;
  }

  return (
    <Box className="tokenswap-container">
      <Grid container className="tokenswap-content">
        <Grid className="tokenswap-box-container">
          <Box
            className="tokenswap-box-token"
            onClick={() => openSelectTokenBox(true)}
          >
            {tokenName ? (
              <Grid className="token">
                <img src={tokenLogo} alt="token logo" />
                <Grid>
                  <Typography className="where-title">{whereTitle}</Typography>
                  <Typography className="name">{tokenName}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid className="token">
                <Typography className="no-token">Select token</Typography>
              </Grid>
            )}

            <Grid className="icon">
              <img src={ArrowDown} alt="arrow" />
            </Grid>
          </Box>
          <input
            className="input-amount"
            type="number"
            value={sliceNumber6Dec(swapAmount)}
            disabled={!tokenName || whereTitle === "Buy"}
            onChange={(e) => {
              if (e.target.value < 0)
                setSwapAmount(0, whereTitle.toLowerCase());
              else setSwapAmount(e.target.value, whereTitle.toLowerCase());
            }}
          />
        </Grid>
        <Grid className="tokenswap-number">
          <Typography className="balance">{`Balance: ${sliceNumber6Dec(
            tokenName ? balance / DECIMAL_18 : 0
          )}`}</Typography>
          <Typography className="price-dolar">{`$${sliceNumber2Dec(
            swapAmount ? convertToDolar() : 0
          )}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
