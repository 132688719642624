import { Button } from "@mui/material";
import "./btn.css";

export default function CustomBtn(props) {
  const { text, className, onClick, variant, disabled } = props;
  return (
    <Button
      variant={variant}
      className={`${className} ${disabled ? "disabled-btn" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
