import { Box, Grid, Typography } from "@mui/material";
import "./staking.css";
import TagInfo from "../../components/TagInfo";
import { useEffect, useState } from "react";
import GrowthIcon from "../../assets/growth-icon.svg";
import BankIcon from "../../assets/bank-icon.svg";
import ListPool from "./table";
import { getStakingInfo } from "../../api/common";
import { formatMoney } from "../../utils/utils";
import {
  getRewardAmount,
  getTimeStaking,
  getTokenBalance,
  getUserInfo,
} from "../../services/smartcontract";
import { useAddress, useNetwork } from "../../contexts/common";
import { DECIMAL_18 } from "../../contants/contract";
import { checkCorrectNetwork } from "../../utils/connectMetamask";

export default function Staking() {
  const [userAddress] = useAddress();
  const [networkId] = useNetwork();
  const [data, setData] = useState([]);
  // const [dataTable, setDataTable] = useState([]);
  const [stakeContract, setStakeContract] = useState("");
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [tvl, setTvl] = useState({
    label: "TVL",
    value: 0,
    icon: BankIcon,
  });

  const [apr, setApr] = useState({
    label: "Highest APR",
    value: 0,
    icon: GrowthIcon,
  });

  useEffect(() => {
    //call api
    getStakingInfo().then((res) => {
      if (res.success && res.message?.status === 200) {
        setApr({ ...apr, value: res.message.data.highestApr });
        setTvl({ ...tvl, value: formatMoney(res.message.data.tvl) });
        setStakeContract(res.message.data.contract);
        const resultFormat = res.message.data.pool.map((item) => {
          item.balance = 0;
          item.stakedAmount = 0;
          item.rewardAmount = 0;
          return item;
        });
        setData(resultFormat);
      }
    });
  }, []);

  useEffect(() => {
    if (userAddress && stakeContract && checkCorrectNetwork(networkId)) {
      if (data && data.length > 0) {
        formatData().then((res) => {
          setData(res);
          setTimeout(() => {
            setToggle((old) => !old);
          }, 1000);
        });
      }
    }
  }, [userAddress, stakeContract, networkId, data, refreshSuccess]);

  async function formatData() {
    return new Promise((resolve, reject) => {
      data.map(async (item) => {
        // console.log("item", item);
        const values = await Promise.all([
          getTokenBalance(userAddress, item.token, item.name?.toLowerCase()),
          getRewardAmount(userAddress, stakeContract, "staking"),
          getUserInfo(userAddress, stakeContract, "staking"),
          getTimeStaking(userAddress, stakeContract),
        ]);
        const formatBalance = values[0] / DECIMAL_18;
        item.balance = formatBalance;
        item.rewardAmount =
          values[1] === "0" ? 0 : (values[1] / DECIMAL_18).toFixed(18);
        item.stakedAmount = values[2].amount / DECIMAL_18;
        item.remainTime = parseInt(values[3].lastDeposit);
      });
      resolve(data);
    });
  }
  return (
    <Box className="staking-container">
      <Grid className="staking-text">
        <Typography className="staking-title">Staking</Typography>
        <Typography className="staking-description">
          Staking and earn.
        </Typography>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        display={"flex"}
        className="staking-tag"
      >
        <Grid container spacing={"24px"}>
          <Grid item xs={12} md={6}>
            <TagInfo
              label={tvl.label}
              value={`$${tvl.value}`}
              icon={tvl.icon}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TagInfo
              label={apr.label}
              value={`${apr.value}%`}
              icon={apr.icon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="list-pool-container">
        <Typography className="pool-title">All Pools</Typography>
        {/*************** List Pool **************/}
        <ListPool
          data={data}
          contractAddress={stakeContract}
          refresh={setRefreshSuccess}
          toggle={toggle}
        />
      </Grid>
    </Box>
  );
}
