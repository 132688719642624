import { Box, Grid } from "@mui/material";
import "./info.css";

export default function CoinInfo(props) {
  const { name, code, logo, price, percent, isIncreased } = props;
  return (
    <Box className="coin-info-container">
      <Grid justifyContent={"space-between"} display={"flex"}>
        <Grid display={"flex"}>
          <Grid className="coin-logo">
            <img src={logo} />
          </Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Grid className="coin-name">{name}</Grid>
            <Grid className="coin-code">{code}</Grid>
          </Grid>
        </Grid>

        <Grid>
          <Grid className="coin-price">{price}</Grid>
          <Grid
            className={
              isIncreased ? "bull-time coin-percent" : "bear-time coin-percent"
            }
          >
            {percent}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
