import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useOpenLoading } from "../../contexts/common";
import "./styles.css";
import LoadingIcon from "../../assets/loading-icon.svg";
export default function Loading() {
  const [open, setOpen] = useOpenLoading();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
      className="custom-loading-container"
    >
      {/* <CircularProgress className="custom-loading" /> */}
      <Box className="custom-loading">
        <img src={LoadingIcon} alt="loading icon" />
        <Typography>Transaction is on processing</Typography>
      </Box>
    </Backdrop>
  );
}
