import { useState, useEffect } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import {
  Box,
  DialogTitle,
  Dialog,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import "./styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAddress, useNetwork } from "../../contexts/common";
import { getListTokenSwap } from "../../api/common";

const WBTCToken = {
  chains: [4200],
  contracts: {
    4200: {
      address: "0xF6D226f9Dc15d9bB51182815b320D3fBE324e1bA",
      decimal: 18,
    },
  },
  icon: "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btc.png",
  name: "Wrapped BTC",
  symbol: "WBTC",
};

// const USDTToken = {
//   chains: [4200],
//   contracts: {
//     4200: {
//       address: "0xF6D226f9Dc15d9bB51182815b320D3fBE324e1bA",
//       decimal: 18,
//     },
//   },
//   icon: "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btc.png",
//   name: "Wrapped BTC",
//   symbol: "WBTC",
// };

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [listTokenOrigin, setListTokenOrigin] = useState([]);
  const [listTokenDisplay, setListTokenDisplay] = useState([]);
  const [commonTokens, setCommonTokens] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    getListTokenSwap().then((res) => {
      if (res.success) {
        setListTokenOrigin(res.message);
        setListTokenDisplay(res.message);
      }
    });
  }, []);

  useEffect(() => {
    const filterArr = listTokenOrigin.filter(
      (token) =>
        token.name.toLowerCase().includes(searchValue) ||
        token.symbol.toLowerCase().includes(searchValue) ||
        token.contracts[token.chains[0]].address
          .toLowerCase()
          .includes(searchValue)
    );
    setListTokenDisplay(filterArr);
  }, [searchValue]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="select-token-container"
    >
      <DialogTitle>Select token</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          color: "#D6CFC3",
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box>
        <Grid className="select-token-search">
          <img src={SearchIcon} alt="search icon" />
          <input
            className="search-token"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Search name or paste address"
          />
        </Grid>
        <Grid className="common-token">
          <Grid className="common-token-title">
            <Typography>Common tokens</Typography>
            <div></div>
          </Grid>
          <Grid className="common-token-list">
            <Box
              className="small-box"
              onClick={() => {
                handleListItemClick(WBTCToken);
              }}
            >
              <img
                src={
                  "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btc.png"
                }
                alt="WBTC icon"
              />
              <Typography>WBTC</Typography>
            </Box>

            {/* <Box className="small-box" onClick={() => handleListItemClick(1)}>
              <img
                src={
                  "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png"
                }
                alt="WBTC icon"
              />
              <Typography>USDT</Typography>
            </Box> */}
          </Grid>
        </Grid>
        <Grid container className="select-token-content">
          {listTokenDisplay.map((token, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
                className="select-token-item"
                onClick={() => handleListItemClick(listTokenDisplay[index])}
              >
                <img src={token.icon} alt="token logo" />
                <Grid className="select-token-text">
                  <Typography>{token.symbol}</Typography>
                  <Typography>{token.name}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Dialog>
  );
}

export default function SelectTokenDialog(props) {
  const { open, setOpen, selectedValue, setSelectedValue } = props;
  // const [selectedValue, setSelectedValue] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
