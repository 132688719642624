import axios from "axios";
// import { apiURL } from "constants/config";
// import { getLocalUserInfo } from "utils/storage";
// import { getLocalToken } from "utils/storage";
// import { clearLocalStorage } from "utils/storage";
// import { setLocalToken } from "utils/storage";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

API.interceptors.response.use(
  (response) => {
    const { status, config } = response;
    return response;
  },
  (error) => {
    console.warn("Error status", error.response.status);
    if (error.response) {
      // return parseError(error.response.data);
      return error.response.data;
    } else {
      return Promise.reject(error);
    }
  }
);

API.interceptors.request.use((request) => {
  return request;
});

export const API_Merlin = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: "",
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
});

export function standardResponse(success, message) {
  return {
    success,
    message,
  };
}
