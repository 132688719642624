import { useEffect, useState } from "react";
import { listCoinFake } from "../../utils/fakeDate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box } from "@mui/material";
import CoinInfo from "../CoinInfo";
import { getListToken } from "../../api/common";
import { sliceNumber2Dec } from "../../utils/utils";
import "./style.css";

var settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  arrows: false,
  centerMode: true,
  variableWidth: true,
};

export default function CoinListPrice() {
  const [listCoin, setListCoin] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleListCoin();
    }, 3000);
  }, []);

  function handleListCoin() {
    getListToken().then((res) => {
      const formatData = res.message?.data.map((coin) => {
        coin.price = "$" + sliceNumber2Dec(coin.usd);
        if (coin.usd_24h_change < 0) {
          coin.isIncreased = false;
          coin.percent = sliceNumber2Dec(coin.usd_24h_change) + "%";
        } else {
          coin.isIncreased = true;
          coin.percent = "+" + sliceNumber2Dec(coin.usd_24h_change) + "%";
        }
        return coin;
      });
      if (res.success) setListCoin(formatData);
    });
  }
  return (
    <Box
      style={{ background: "#201F1E", padding: "28px 0" }}
      className="coinlist-container"
    >
      <Slider {...settings}>
        {listCoin.map((coin, idx) => {
          return (
            <CoinInfo
              key={idx}
              name={coin.name}
              code={coin.symbol}
              logo={coin.logo}
              price={coin.price}
              percent={coin.percent}
              isIncreased={coin.isIncreased}
            />
          );
        })}
      </Slider>
    </Box>
  );
}
