import { Box, Grid, Typography } from "@mui/material";
import "./yield.css";
import TagInfo from "../../components/TagInfo";
import { useEffect, useState } from "react";
import GrowthIcon from "../../assets/growth-icon.svg";
import BankIcon from "../../assets/bank-icon.svg";
import ListPool from "./table";
import { getYieldFarmInfo } from "../../api/common";
import { formatMoney } from "../../utils/utils";
import { useAddress, useNetwork } from "../../contexts/common";
import { checkCorrectNetwork } from "../../utils/connectMetamask";
import {
  getRewardAmountYield,
  getTokenBalance,
  getUserInfoYield,
  getTimeYield,
} from "../../services/smartcontract";
import { DECIMAL_18 } from "../../contants/contract";

export default function YieldFarm() {
  const [userAddress] = useAddress();
  const [networkId] = useNetwork();
  const [yieldContract, setYieldContract] = useState("");
  const [data, setData] = useState([]);
  const [currentStake, setCurrentStake] = useState([]);
  const [tvl, setTvl] = useState({
    label: "TVL",
    value: 0,
    icon: BankIcon,
  });

  const [apr, setApr] = useState({
    label: "Highest APR",
    value: 0,
    icon: GrowthIcon,
  });
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    getYieldFarmInfo().then((res) => {
      if (res.success && res.message?.status === 200) {
        setApr({ ...apr, value: res.message.data.highestApr });
        setTvl({ ...tvl, value: formatMoney(res.message.data.tvl) });
        setYieldContract(res.message.data.contract);
        const resultFormat = res.message.data.pool.map((item) => {
          item.balance1 = 0;
          item.balance2 = 0;
          item.stakedAmount = 0;
          item.rewardAmount = 0;
          return item;
        });
        setData(resultFormat);
      }
    });
  }, []);

  useEffect(() => {
    if (userAddress && yieldContract && checkCorrectNetwork(networkId)) {
      if (data && data.length > 0)
        formatData().then((res) => {
          setData(res);
          setTimeout(() => {
            setToggle((old) => !old);
          }, 1000);
        });
    }
  }, [userAddress, yieldContract, networkId, data, refreshSuccess]);

  async function formatData() {
    return new Promise((resolve, reject) => {
      data.map(async (item) => {
        const values = await Promise.all([
          getTokenBalance(userAddress, item.token1, item.name1?.toLowerCase()),
          getTokenBalance(userAddress, item.token2, item.name2?.toLowerCase()),
          getRewardAmountYield(
            userAddress,
            yieldContract,
            "yield",
            item.poolId
          ),
          getUserInfoYield(userAddress, yieldContract, "yield", item.poolId),
          getTimeYield(userAddress, yieldContract, item.poolId),
        ]);
        item.balance1 = values[0] / DECIMAL_18;
        item.balance2 = values[1] / DECIMAL_18;
        item.rewardAmount =
          values[2] === "0" ? 0 : (values[2] / DECIMAL_18).toFixed(18);
        item.stakedAmount =
          (parseInt(values[3].amount1) * item.price1 +
            parseInt(values[3].amount2) * item.price2) /
          DECIMAL_18;
        item.remainTime = parseInt(values[4].lastDeposit);
      });
      resolve(data);
    });
  }

  return (
    <Box className="staking-container">
      <Grid className="staking-text">
        <Typography className="staking-title">Yield Farm</Typography>
        <Typography className="staking-description">
          Yield Farm and earn.
        </Typography>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        display={"flex"}
        className="staking-tag"
      >
        <Grid container spacing={"24px"}>
          <Grid item xs={12} md={6}>
            <TagInfo
              label={tvl.label}
              value={`$${tvl.value}`}
              icon={tvl.icon}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TagInfo
              label={apr.label}
              value={`${apr.value}%`}
              icon={apr.icon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="list-pool-container">
        <Typography className="pool-title">All Pools</Typography>
        {/*************** List Pool **************/}
        <ListPool
          data={data}
          contractAddress={yieldContract}
          refresh={setRefreshSuccess}
          toggle={toggle}
        />
      </Grid>
    </Box>
  );
}
