import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Logo from "../../assets/logo.png";
import "./footer.css";
import { telegram, x, discord, whitepaper } from "../../contants/media";

//Community icon
import XIcon from "../../assets/x.png";
import TelegramIcon from "../../assets/telegram.png";
import DiscordIcon from "../../assets/discord.png";
import FlickrIcon from "../../assets/flickr.png";
import { useHistory } from "react-router-dom";

export default function Footer() {
  const history = useHistory();
  return (
    <Box
      style={{
        background: "#201F1E",
        padding: "100px 120px 31px 120px",
      }}
      className="footer-container"
    >
      <Grid container className="footer-content">
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "inherit",
          }}
        >
          <img src={Logo} alt="logo" style={{ width: "192px" }} />
          <Typography color={"#95928D"} className="footer-broom">
            © 2024 Broomswap
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="footer-list">
          <Typography className="title">Documentation</Typography>
          <Typography className="item">Gitbook</Typography>
          <Typography
            className="item"
            onClick={() => window.open(whitepaper, "_blank")}
          >
            Whitepaper
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="footer-list">
          <Typography className="title">Product</Typography>
          <Typography className="item" onClick={() => history.push("/swap")}>
            Swap
          </Typography>
          <Typography className="item" onClick={() => history.push("/staking")}>
            Staking
          </Typography>
          <Typography
            className="item"
            onClick={() => history.push("/yield-farm")}
          >
            Yield Farm
          </Typography>
          <Typography
            className="item"
            onClick={() => history.push("/airdrops")}
          >
            Airdrops
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className="footer-list list-icon">
          <Typography className="title">Social</Typography>
          <Box>
            <img
              src={XIcon}
              alt="social icon"
              onClick={() => window.open(x, "_blank")}
            />
            <img
              src={TelegramIcon}
              alt="social icon"
              onClick={() => window.open(telegram, "_blank")}
            />
            <img
              src={DiscordIcon}
              alt="social icon"
              onClick={() => window.open(discord, "_blank")}
            />
            {/* <img src={FlickrIcon} alt="social icon" /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
