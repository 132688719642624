export function collapseAddress(address: string) {
  const first5Letters = address.slice(0, 5);
  const last5Letters = address.slice(address.length - 5, address.length);
  return `${first5Letters}...${last5Letters}`;
}

export function sliceNumber1Dec(number) {
  const [beforeDot, afterDot] = number.toString().split(".");
  const afterDotTrimmed = afterDot?.slice(0, 1)
    ? "." + afterDot?.slice(0, 1)
    : "";
  return beforeDot + afterDotTrimmed;
}

export function sliceNumber2Dec(number) {
  const [beforeDot, afterDot] = number.toString().split(".");
  const afterDotTrimmed = afterDot?.slice(0, 2)
    ? "." + afterDot?.slice(0, 2)
    : "";
  return beforeDot + afterDotTrimmed;
}

export function sliceNumber6Dec(number) {
  const [beforeDot, afterDot] = number.toString().split(".");
  const afterDotTrimmed = afterDot?.slice(0, 6)
    ? "." + afterDot?.slice(0, 6)
    : "";
  return beforeDot + afterDotTrimmed;
}

export function sliceNumber10Dec(number) {
  const [beforeDot, afterDot] = number.toString().split(".");
  const afterDotTrimmed = afterDot?.slice(0, 10)
    ? "." + afterDot?.slice(0, 10)
    : "";
  return beforeDot + afterDotTrimmed;
}

export function formatMoney(price) {
  return new Intl.NumberFormat().format(price);
}

export function checkEnoughBalance(amount, balance, cb) {
  if (amount > balance) {
    cb();
    return false;
  } else return true;
}
