import { Box, Snackbar, Typography } from "@mui/material";
import { useOpenNotiBar } from "../../contexts/common";
import "./styles.css";
import SuccessIcon from "../../assets/success-icon.svg";
import FailedIcon from "../../assets/error-icon.svg";

export default function SnackBar() {
  const [openNotiBar, setOpenNotiBar] = useOpenNotiBar();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpenNotiBar({ ...openNotiBar, isOpen: false });
      return;
    }
    setOpenNotiBar({ ...openNotiBar, isOpen: false });
  };

  return (
    <Snackbar
      sx={{ height: "100%" }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openNotiBar.isOpen}
      onClose={handleClose}
      message={openNotiBar.message}
      autoHideDuration={3000}
      className={`snack-bar-container`}
    >
      <Box className="snack-bar">
        <img
          src={openNotiBar.type === "success" ? SuccessIcon : FailedIcon}
          alt="icon"
        />
        <Typography>{openNotiBar.message}</Typography>
      </Box>
    </Snackbar>
  );
}
