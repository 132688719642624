import { useState, useEffect } from "react";
import MetamaskIcon from "../../assets/wallets/metamask-logo.png";
import UnisatIcon from "../../assets/wallets/unisat-logo.png";
import {
  Box,
  DialogTitle,
  Dialog,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import "./styles.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  connectMetamask,
  checkCorrectNetwork,
  requiredSwitchNetwork,
  checkMetamaskConnected,
} from "../../utils/connectMetamask";
import { useAddress, useBalance, useNetwork } from "../../contexts/common";
import { DECIMAL_18 } from "../../contants/contract";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [userAddress, setUserAddress] = useAddress();
  const [balance, setBalance] = useBalance();
  const [networkId, setNetworkId] = useNetwork();
  const pathArray = window.location.pathname.split("/");
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    try {
      checkMetamaskConnected().then((res) => {
        if (res) connecMetamaskWallet();
      });
    } catch (e) {
      console.log("catch:", e);
    }

    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  useEffect(() => {
    //correct network
    verifyAccountAndNetwork();
  }, [userAddress, networkId, pathArray[1]]);

  function onFocus() {
    // console.log("tab focus");
    verifyAccountAndNetwork();
  }

  function verifyAccountAndNetwork() {
    if (
      userAddress &&
      networkId &&
      checkCorrectNetwork(networkId, pathArray[1])
    ) {
    }
    //wrong network
    else if (
      userAddress &&
      networkId &&
      !checkCorrectNetwork(networkId, pathArray[1])
    ) {
      switchNetwork();
    }
  }

  /************** Metamask *****************/
  async function checkInstallMetamask() {
    handleClose();
    if (!window.ethereum) {
      //not install metamask
      window.open("https://metamask.io/download", "_blank");
    } else if (window.ethereum && !userAddress) {
      //install metamask but not connect
      connecMetamaskWallet();
    }
  }

  useEffect(() => {
    console.log(balance);
  }, [balance]);
  function connecMetamaskWallet() {
    connectMetamask().then((result) => {
      if (result) {
        setUserAddress(result.address);
        setNetworkId(result.network);
        setBalance(result.balance);
      }
    });
  }

  function switchNetwork() {
    const chainId =
      pathArray[1] === "swap"
        ? process.env.REACT_APP_MERLIN_TESTNET_CHAIN_ID_HEX
        : process.env.REACT_APP_SEPOLIA_TESTNET_CHAIN_ID_HEX;
    requiredSwitchNetwork(chainId).then((result) => {
      setNetworkId(result);
    });
  }
  /*****************End Metasmask *********/

  const listWallet = [
    {
      icon: MetamaskIcon,
      name: "Metamask",
      connect: () => checkInstallMetamask(),
    },
    {
      icon: UnisatIcon,
      name: "Unisat",
    },
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="wallet-dialog-container"
    >
      <DialogTitle>Select your wallet</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#D6CFC3",
          // color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box>
        <Grid container className="wallet-dialog-content">
          {listWallet.map((wallet, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
                className="wallet-dialog-item"
                onClick={wallet.connect}
              >
                <img src={wallet.icon} alt="wallet logo" />
                <Typography>{wallet.name}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Dialog>
  );
}

export default function WalletDialog(props) {
  const { open, setOpen } = props;
  const [selectedValue, setSelectedValue] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  return (
    <>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
