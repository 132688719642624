import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import UpIcon from "../../../assets/up.svg";
import DownIcon from "../../../assets/down.svg";
import CustomBtn from "../../../components/CustomBtn";
import {
  approveToken,
  checkAllowanceToken,
  claimStaking,
  staking,
  unstaking,
} from "../../../services/smartcontract";
import {
  useAddress,
  useOpenNotiBar,
  useOpenLoading,
  useListTokenPrice,
} from "../../../contexts/common";
import {
  checkEnoughBalance,
  sliceNumber10Dec,
  sliceNumber6Dec,
} from "../../../utils/utils";
import { DECIMAL_18, SEVEN_DAY, TWO_DAY } from "../../../contants/contract";
import PenaltyDialog from "../../../components/PenaltyDialog";

export default function ListPool(props) {
  const { data, contractAddress, refresh, toggle } = props;
  const [userAddress] = useAddress();
  const [, setOpenNotiBar] = useOpenNotiBar();
  const [, setOpenLoading] = useOpenLoading();
  const [listTokenPrice] = useListTokenPrice();
  const [currentStake, setCurrentStake] = useState(0);
  const [unstakeAmount, setUnstakeAmount] = useState(0);
  const [openPenaltyBox, setOpenPenaltyBox] = useState(false);
  const [currentRemainTime, setCurrentRemainTime] = useState(0);

  async function isAllowance(contractAddress, tokenAddress) {
    const allowance = await checkAllowanceToken(
      userAddress,
      contractAddress,
      tokenAddress
    );
    if (allowance > 0) {
      return;
    } else {
      await approveToken(userAddress, contractAddress, tokenAddress, () =>
        setOpenLoading(true)
      );
    }
  }

  async function handleStake(index: number) {
    const checkEnough = checkEnoughBalance(
      currentStake,
      data[index].balance,
      () =>
        setOpenNotiBar({
          isOpen: true,
          message: "Insufficient balance!",
          type: "error",
        })
    );
    if (!checkEnough) return;

    setOpenLoading(true);
    await isAllowance(contractAddress, data[index].token);

    staking(userAddress, contractAddress, "staking", currentStake, () =>
      setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function handleUnstake() {
    unstaking(userAddress, contractAddress, "staking", unstakeAmount, () =>
      setOpenLoading(true)
    )
      .then((res) => {
        console.log(res);
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function handleClaim() {
    claimStaking(userAddress, contractAddress, "staking", () =>
      setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function convertDollar(token) {
    const tokenPrice = listTokenPrice["WBTC"];
    return `$${sliceNumber6Dec(token * tokenPrice)}`;
  }

  function isAbleClaim(timestamp) {
    const days = Math.floor(new Date().getTime() / 1000) - timestamp;
    return days > TWO_DAY;
  }

  function isAbleUnstake(timestamp) {
    const days = Math.floor(new Date().getTime() / 1000) - timestamp;
    return days > SEVEN_DAY;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "pool",
        header: "Pool",
        size: 50,
        Cell: ({ row }) => (
          <Box display={"flex"}>
            <img
              src={row.original.logo}
              alt="pool icon"
              style={{ width: "24px", marginRight: "8px" }}
            />
            <Typography sx={{ color: "#FBF6EC" }}>
              {row.original.name}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: "total",
        header: "Total Staked",
        size: 75,
        Cell: ({ row }) => (
          <Typography sx={{ color: "#FBF6EC" }}>
            {row.original.total} {row.original.name}
          </Typography>
        ),
      },
      {
        accessorKey: "apr",
        header: "APR",
        size: 75,
        Cell: ({ row }) => (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              background:
                "linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900);",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            }}
          >
            {`${row.original.apr}%`}
          </Typography>
        ),
      },
      // {
      //   accessorKey: "total_deposit",
      //   header: "Total Deposit",
      //   size: 75,
      //   Cell: ({ row }) => (
      //     <Typography sx={{ color: "#95928D" }}>
      //       ${row.original.total_deposit | 0}
      //     </Typography>
      //   ),
      // },
      {
        accessorKey: "reward",
        header: "Reward to claim",
        size: 75,
        Cell: ({ row }) => (
          <Typography sx={{ color: "#95928D" }}>
            {row.original.rewardAmount} {row.original.name}
          </Typography>
        ),
      },
    ],
    [toggle]
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowActions: false,
    enableTopToolbar: false,
    enableSorting: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableExpandAll: true,
    paginationDisplayMode: "pages",
    positionExpandColumn: "last",
    enableRowNumbers: true,
    muiExpandAllButtonProps: ({ table }) => ({
      children: <Box></Box>,
      onClick: () => {},
    }),
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        width: "80px",
      },
      children: row.getIsExpanded() ? (
        <Box className="small-box">
          <Typography>Hide</Typography>
          <img src={UpIcon} alt="icon arrow" />
        </Box>
      ) : (
        <Box className="small-box">
          <Typography>Show</Typography>
          <img src={DownIcon} alt="icon arrow" />
        </Box>
      ),
    }),
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <Grid container spacing={"12px"}>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Grid className="balance">
                <Typography className="label">Your balance:</Typography>
                <Typography className="value">
                  {`${sliceNumber6Dec(row.original.balance)} ${
                    row.original.name
                  }`}
                </Typography>
              </Grid>
              <Grid className="input-stake">
                <Grid>
                  <input
                    type="number"
                    onChange={(e) => {
                      if (e.target.value < 0) setCurrentStake(0);
                      else setCurrentStake(e.target.value);
                    }}
                    value={currentStake}
                  />
                  <Typography>{convertDollar(currentStake)}</Typography>
                </Grid>
                <Grid>
                  <Box
                    className="small-box"
                    onClick={() => setCurrentStake(row.original.balance)}
                  >
                    <Typography>Max</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid className="btn">
                <CustomBtn
                  text={"Stake"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={currentStake == 0}
                  onClick={() => handleStake(row.index)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Typography className="title">Your Stake</Typography>
              <Grid className="value">
                <img src={row.original.logo} alt="icon" />
                <Typography>{`${row.original.stakedAmount} ${row.original.name}`}</Typography>
              </Grid>
              <Grid className="btn">
                <CustomBtn
                  text={"Unstake"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={row.original.stakedAmount === 0 ? true : false}
                  onClick={() => {
                    if (isAbleUnstake(row.original.remainTime)) {
                      handleUnstake();
                      setUnstakeAmount(row.original.stakedAmount);
                      setCurrentRemainTime(row.original.remainTime);
                    } else {
                      setUnstakeAmount(row.original.stakedAmount);
                      setOpenPenaltyBox(true);
                      setCurrentRemainTime(row.original.remainTime);
                    }
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="stake-inform-rule">
                  *Unstake within the first 7 days will only receive 70% of the
                  deposit.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Typography className="title">Your reward to claim</Typography>
              <Grid className="value">
                <img src={row.original.logo} alt="icon" />
                <Typography className="slice-amount">
                  {sliceNumber10Dec(row.original.rewardAmount)}
                </Typography>
                <Typography>{row.original.name}</Typography>
              </Grid>
              <Grid className="btn">
                <CustomBtn
                  text={"Claim"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={
                    row.original.rewardAmount === 0 ||
                    !isAbleClaim(row.original.remainTime)
                      ? true
                      : false
                  }
                  onClick={() => handleClaim()}
                />
              </Grid>
              <Grid>
                <Typography className="stake-inform-rule">
                  *Reward will be available to claim after 2 days from the stake
                  request
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ),
    renderEmptyRowsFallback: ({ table }) => (
      <Box className="no-record">No record to display</Box>
    ),
  });

  return (
    <>
      <PenaltyDialog
        open={openPenaltyBox}
        setOpen={setOpenPenaltyBox}
        unstake={handleUnstake}
        remainTime={currentRemainTime}
      />
      <MaterialReactTable table={table} />
    </>
  );
}
