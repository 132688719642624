/* eslint-disable */

import Web3 from "web3";

const web3 = new Web3(Web3.givenProvider);

/**
 * Check network is correct
 * bsc testnet on staging
 * bsc on product
 * return true if correct network
 *        false if wrong network
 */
export function checkCorrectNetwork(network, path) {
  // console.log("netowkr: ", network);
  if (path === "swap") {
    //swap
    if (
      network.toString() === process.env.REACT_APP_MERLIN_TESTNET_CHAIN_ID_DEC
    ) {
      //merlin testnet
      return true;
    } else return false;
  } else {
    if (
      network.toString() === process.env.REACT_APP_SEPOLIA_TESTNET_CHAIN_ID_DEC
    ) {
      // sepolia testnet
      return true;
    } else return false;
  }
}

/**
 *
 * @returns address,netwok or false
 */
export async function connectMetamask() {
  return new Promise(async (resolve, reject) => {
    if (!window.ethereum) {
      // browser do not have any wallet extension
      resolve(false);
    } else if (window.ethereum.isMetaMask) {
      //browser has metamask
      const result = await requiredConnectWallet();
      console.log("result connect: ", result);
      resolve({
        address: result.address,
        network: result.network,
        balance: result.balance,
      });
    }
  });
}

/**
 *
 * @returns boolean
 */
export async function checkMetamaskConnected() {
  return new Promise(async (resolve, reject) => {
    try {
      const acc = await window.ethereum.request({ method: "eth_accounts" });
      // const isUnlocked = await window?.ethereum?._metamask.isUnlocked();
      // console.log(isUnlocked);
      if (acc.length > 0) resolve(true);
      else resolve(false);
    } catch (error) {
      //sth wrong when connect metamask
      console.log(error);
      resolve(false);
    }
  });
}

/**
 *
 * @returns address,network
 */
export async function requiredConnectWallet() {
  return new Promise(async (resolve, reject) => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const network = await web3.eth.net.getId();
      const balance = await web3.eth.getBalance(accounts[0]);
      resolve({
        address: accounts[0],
        network: network,
        balance: balance,
      });
    } catch (error) {
      //sth wrong when connect metamask
      console.log("require network: ", error);
      resolve({
        address: null,
        network: null,
        balance: 0,
      });
    }
  });
}

/**
 *
 * @returns network
 */
export async function requiredSwitchNetwork(networkId) {
  // console.log("rq switch:", networkId);
  return new Promise(async (resolve, reject) => {
    try {
      if (process.env.REACT_APP_ENV === "prod") {
        //env production -> merlin mainnet
        let params = [
          {
            chainId: process.env.REACT_APP_MERLIN_TESTNET_CHAIN_ID_HEX,
            chainName: "Merlin Mainnet",
            rpcUrls: ["https://rpc.merlinchain.io"],
            nativeCurrency: {
              name: "BTC",
              symbol: "BTC",
              decimals: 18,
            },
            blockExplorerUrls: ["https://scan.merlinchain.io"],
          },
        ];
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: params,
        });
      } else {
        //env dev -> merlin testnet / sepolia testnet
        let params = [
          {
            chainId: networkId,
          },
        ];
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: params,
        });
      }

      const chainId = await web3.eth.net.getId();
      resolve(chainId);
    } catch (switchError) {
      //Metamask do not have that network, need to add and switch
      console.log(switchError);
      // reject(addError);
      // console.log(addError);
    }
  });
}

/**
 *
 * @param {*} address
 * @returns string
 */
export function reduceAddressLength(address: string) {
  if (address && address.length > 1) {
    return (
      address.slice(0, 6) +
      "..." +
      address.slice(address.length - 4, address.length)
    );
  } else return address;
}

/**
 *
 * @param {*} address
 * @returns object
 */
export async function signedLogin(address: string) {
  return new Promise(async (resolve, reject) => {
    const time = Date.now();
    const message = address + ":" + time;
    // console.log('message:' + message)
    try {
      let result = await web3.eth.personal.sign(message, address);
      // console.log('result: ')
      // console.log(result)
      resolve({
        address: address,
        unixTime: time,
        signedTx: result,
      });
    } catch (err) {
      reject("user cancel");
    }
  });
}

/**
 *
 * @param {*} addresses
 * @returns true if 2 address the same, false if not
 */
export function compareAddresses(address: string, localAddress: string) {
  if (address && address === localAddress) return true;
  else return false;
}

/**
 *
 * @returns balance
 */
export async function getBalanceWallet(address) {
  try {
    const balance = await web3.eth.getBalance(address);
    return balance;
  } catch (err) {
    return 0;
  }
}
