import { Switch, Route, BrowserRouter } from "react-router-dom";
import ContextProvider, {
  useListTokenPrice,
  useListPool,
} from "./contexts/common";
import Home from "./pages/Home";
import Menu from "./components/Menu";
import ComingSoon from "./pages/ComingSoon";
import Staking from "./pages/Staking";
import YieldFarm from "./pages/YieldFarm";
import SnackBar from "./components/SnackBar";
import Loading from "./components/Loading";
import Swap from "./pages/Swap";
import { useEffect } from "react";
import { getListPool, getListTokenPrice } from "./api/common";

function Layout({ children }) {
  const [, setListTokenPrice] = useListTokenPrice();
  const [, setListPool] = useListPool();
  useEffect(() => {
    getListTokenPrice().then((res) => {
      if (res.success) {
        setListTokenPrice(res.message.data);
      }
    });
    getListPool().then((res) => {
      if (res.success) {
        setListPool(res.message.data);
      }
    });
  }, []);

  return (
    <>
      <Menu />
      <SnackBar />
      <Loading />
      {children}
    </>
  );
}
function Common() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          strict
          path="/"
          render={() => {
            return (
              <Layout>
                <Home />
              </Layout>
            );
          }}
        />
        <Route
          exact
          strict
          path="/swap"
          render={() => {
            return (
              <Layout>
                {/* <ComingSoon /> */}
                <Swap />
              </Layout>
            );
          }}
        />
        <Route
          exact
          strict
          path="/pool"
          render={() => {
            return (
              <Layout>
                <ComingSoon />
              </Layout>
            );
          }}
        />
        <Route
          exact
          strict
          path="/yield-farm"
          render={() => {
            return (
              <Layout>
                {/* <ComingSoon /> */}
                <YieldFarm />
              </Layout>
            );
          }}
        />
        <Route
          exact
          strict
          path="/staking"
          render={() => {
            return (
              <Layout>
                {/* <ComingSoon /> */}
                <Staking />
              </Layout>
            );
          }}
        />
        <Route
          exact
          strict
          path="/airdrops"
          render={() => {
            return (
              <Layout>
                <ComingSoon />
              </Layout>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default function App() {
  return (
    <ContextProvider>
      <Common />
    </ContextProvider>
  );
}
