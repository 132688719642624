import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import UpIcon from "../../../assets/up.svg";
import DownIcon from "../../../assets/down.svg";
import CustomBtn from "../../../components/CustomBtn";
import {
  checkEnoughBalance,
  sliceNumber10Dec,
  sliceNumber6Dec,
} from "../../../utils/utils";
import {
  approveToken,
  checkAllowanceToken,
  claimYield,
  fetchPriceToken,
  getTokenBalance,
  stakingYield,
  unstakingYield,
} from "../../../services/smartcontract";
import {
  useAddress,
  useListTokenPrice,
  useOpenLoading,
  useOpenNotiBar,
} from "../../../contexts/common";
import { DECIMAL_18, SEVEN_DAY, TWO_DAY } from "../../../contants/contract";
import PenaltyDialog from "../../../components/PenaltyDialog";

export default function ListPool(props) {
  const { data, contractAddress, refresh, toggle } = props;
  const [currentStake, setCurrentStake] = useState([]);
  const [userAddress] = useAddress();
  const [, setOpenNotiBar] = useOpenNotiBar();
  const [, setOpenLoading] = useOpenLoading();
  const [listTokenPrice] = useListTokenPrice();
  const [exchangeRate, setExchangeRate] = useState([]);
  const [currentPool, setCurrentPool] = useState(0);
  const [currentRemainTime, setCurrentRemainTime] = useState(0);
  const [openPenaltyBox, setOpenPenaltyBox] = useState(false);

  useEffect(() => {
    const exchangeRate = [];
    if (data && data.length > 0) {
      data.map(async (item) => {
        exchangeRate.push(1);
        currentStake[item.poolId] = {
          balance1: 0,
          balance2: 0,
        };
      });
    }
    setExchangeRate([...exchangeRate]);
  }, [data]);

  async function handleFetchPrice(address1, address2, index, isBalance1, fee) {
    if (isBalance1) {
      currentStake[index].balance2 =
        (currentStake[index].balance1 * data[index].price1) /
        data[index].price2;
    } else {
      currentStake[index].balance1 =
        (currentStake[index].balance2 * data[index].price2) /
        data[index].price1;
    }
  }

  // async function handleFetchPrice(address1, address2, index, isBalance1, fee) {
  //   if (process.env.REACT_APP_ENV === "prod") {
  //     const price = await fetchPriceToken(
  //       process.env.REACT_APP_QUOTE_ADDRESS,
  //       address1,
  //       address2,
  //       fee
  //     );
  //     exchangeRate[index] = price.acquire / DECIMAL_18;
  //     setExchangeRate([...exchangeRate]);
  //     if (isBalance1) {
  //       currentStake[index].balance2 =
  //         currentStake[index].balance1 * exchangeRate[index];
  //     } else {
  //       currentStake[index].balance1 =
  //         currentStake[index].balance2 / exchangeRate[index];
  //     }
  //   } else {
  //     if (isBalance1) {
  //       currentStake[index].balance2 =
  //         currentStake[index].balance1 * exchangeRate[index];
  //     } else {
  //       currentStake[index].balance1 =
  //         currentStake[index].balance2 / exchangeRate[index];
  //     }
  //   }
  // }

  async function isAllowance(contractAddress, tokenAddress) {
    const allowance = await checkAllowanceToken(
      userAddress,
      contractAddress,
      tokenAddress
    );
    if (allowance > 0) {
      return;
    } else {
      await approveToken(userAddress, contractAddress, tokenAddress, () =>
        setOpenLoading(true)
      );
    }
  }

  async function handleStake(index, tokenAddress_1, tokenAddress_2, poolId) {
    const checkEnough1 = checkEnoughBalance(
      currentStake[poolId].balance1,
      data[index].balance1,
      () =>
        setOpenNotiBar({
          isOpen: true,
          message: "Insufficient balance!",
          type: "error",
        })
    );

    const checkEnough2 = checkEnoughBalance(
      currentStake[poolId].balance2,
      data[index].balance2,
      () =>
        setOpenNotiBar({
          isOpen: true,
          message: "Insufficient balance!",
          type: "error",
        })
    );
    if (!checkEnough1 || !checkEnough2) return;

    setOpenLoading(true);
    await isAllowance(contractAddress, tokenAddress_1);

    await isAllowance(contractAddress, tokenAddress_2);

    stakingYield(
      userAddress,
      contractAddress,
      "yield",
      tokenAddress_1,
      currentStake[poolId].balance1,
      tokenAddress_2,
      currentStake[poolId].balance2,
      poolId,
      () => setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function handleUnstake() {
    unstakingYield(
      userAddress,
      contractAddress,
      "yield",
      0,
      0,
      currentPool,
      () => setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function handleClaim(poolId) {
    claimYield(userAddress, contractAddress, "yield", poolId, () =>
      setOpenLoading(true)
    )
      .then((res) => {
        setOpenNotiBar({
          isOpen: true,
          message: "Successful transaction",
          type: "success",
        });
        refresh((old) => !old);
      })
      .catch((e) => {
        console.log("errrrr:", e);
        setOpenNotiBar({
          isOpen: true,
          message: "Transaction failed",
          type: "error",
        });
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  function convertDollar(token, tokenName) {
    const tokenPrice = listTokenPrice[tokenName];
    return `$${sliceNumber6Dec(token * tokenPrice)}`;
  }

  function isAbleClaim(timestamp) {
    const days = Math.floor(new Date().getTime() / 1000) - timestamp;
    return days > TWO_DAY;
  }

  function isAbleUnstake(timestamp) {
    const days = Math.floor(new Date().getTime() / 1000) - timestamp;
    return days > SEVEN_DAY;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "pool",
        header: "Pool",
        size: 50,
        Cell: ({ row }) => (
          <Box display={"flex"}>
            <Box className="yield-group-icon">
              <img
                src={row.original.logo1}
                alt="pool icon"
                style={{ width: "24px", marginRight: "8px" }}
              />
              <img
                src={row.original.logo2}
                alt="pool icon"
                style={{ width: "24px", marginRight: "8px" }}
              />
            </Box>

            <Typography sx={{ color: "#FBF6EC" }}>
              {row.original.name}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: "total",
        header: "Total Staked",
        size: 100,
        Cell: ({ row }) => (
          <Typography sx={{ color: "#FBF6EC" }}>
            {/* {row.original.tvl} {row.original.name} */}
            {`${row.original.tvl} USD`}
          </Typography>
        ),
      },
      {
        accessorKey: "apr",
        header: "APR",
        size: 75,
        Cell: ({ row }) => (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              background:
                "linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900);",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            }}
          >
            {`${row.original.apr}%`}
          </Typography>
        ),
      },
      // {
      //   accessorKey: "total_deposit",
      //   header: "Total Deposit",
      //   size: 75,
      //   Cell: ({ row }) => (
      //     <Typography sx={{ color: "#95928D" }}>
      //       ${row.original.total_deposit | 0}
      //     </Typography>
      //   ),
      // },
      {
        accessorKey: "reward",
        header: "Reward to claim",
        size: 75,
        Cell: ({ row }) => (
          <Typography sx={{ color: "#95928D" }}>
            {row.original.rewardAmount} {row.original.rewardName}
          </Typography>
        ),
      },
    ],
    [toggle]
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowActions: false,
    enableTopToolbar: false,
    enableSorting: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableExpandAll: true,
    paginationDisplayMode: "pages",
    positionExpandColumn: "last",
    enableRowNumbers: true,
    muiExpandAllButtonProps: ({ table }) => ({
      children: <Box></Box>,
      onClick: () => {},
    }),
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        width: "80px",
      },
      children: row.getIsExpanded() ? (
        <Box className="small-box">
          <Typography>Hide</Typography>
          <img src={UpIcon} alt="icon arrow" />
        </Box>
      ) : (
        <Box className="small-box">
          <Typography>Show</Typography>
          <img src={DownIcon} alt="icon arrow" />
        </Box>
      ),
    }),
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <Grid container spacing={"12px"}>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Grid>
                {/* Token first */}
                <Grid>
                  <Grid className="balance">
                    <Typography className="label">Your balance:</Typography>
                    <Typography className="value">
                      {`${sliceNumber6Dec(row.original.balance1)} ${
                        row.original.name1
                      }`}
                    </Typography>
                  </Grid>
                  <Grid className="input-stake">
                    <Grid>
                      <input
                        type="number"
                        onChange={(e) => {
                          currentStake[row.original.poolId] = {
                            ...currentStake[row.original.poolId],
                            balance1: e.target.value < 0 ? 0 : e.target.value,
                          };
                          setCurrentStake([...currentStake]);
                          handleFetchPrice(
                            row.original.token1,
                            row.original.token2,
                            row.index,
                            true,
                            2000
                          );
                        }}
                        value={currentStake[row.original.poolId]?.balance1}
                      />
                      <Typography>
                        {convertDollar(
                          currentStake[row.original.poolId]?.balance1
                            ? currentStake[row.original.poolId]?.balance1
                            : 0,
                          row.original.name1
                        )}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Box
                        className="small-box"
                        onClick={() => {
                          currentStake[row.original.poolId] = {
                            ...currentStake[row.original.poolId],
                            balance1: row.original.balance1,
                          };
                          setCurrentStake([...currentStake]);
                        }}
                      >
                        <Typography>Max</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Token second */}
                <Grid>
                  <Grid className="balance">
                    <Typography className="label">Your balance:</Typography>
                    <Typography className="value">
                      {`${sliceNumber6Dec(row.original.balance2)} ${
                        row.original.name2
                      }`}
                    </Typography>
                  </Grid>
                  <Grid className="input-stake">
                    <Grid>
                      <input
                        type="number"
                        onChange={(e) => {
                          currentStake[row.original.poolId] = {
                            ...currentStake[row.original.poolId],
                            balance2: e.target.value < 0 ? 0 : e.target.value,
                          };
                          handleFetchPrice(
                            row.original.token1,
                            row.original.token2,
                            row.index,
                            false,
                            2000
                          );
                          setCurrentStake([...currentStake]);
                        }}
                        value={currentStake[row.original.poolId]?.balance2}
                      />
                      <Typography>
                        {convertDollar(
                          currentStake[row.original.poolId]?.balance2
                            ? currentStake[row.original.poolId]?.balance2
                            : 0,
                          row.original.name2
                        )}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Box
                        className="small-box"
                        onClick={() => {
                          currentStake[row.original.poolId] = {
                            ...currentStake[row.original.poolId],
                            balance2: row.original.balance2,
                          };
                          setCurrentStake([...currentStake]);
                        }}
                      >
                        <Typography>Max</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className="btn">
                <CustomBtn
                  text={"Stake"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={
                    currentStake[row.original.poolId]?.balance1 === 0 ||
                    currentStake[row.original.poolId]?.balance2 === 0
                  }
                  onClick={() =>
                    handleStake(
                      row.index,
                      row.original.token1,
                      row.original.token2,
                      row.original.poolId
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Typography className="title">Your Stake</Typography>
              <Grid className="value">
                <Box className="yield-group-icon">
                  <img src={row.original.logo1} alt="icon" />
                  <img src={row.original.logo2} alt="icon" />
                </Box>
                <Typography>{`${row.original.stakedAmount} USD`}</Typography>
              </Grid>
              <Grid className="btn">
                <CustomBtn
                  text={"Unstake"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={row.original.stakedAmount === 0 ? true : false}
                  onClick={() => {
                    if (isAbleUnstake(row.original.remainTime)) {
                      setCurrentPool(row.original.poolId);
                      handleUnstake();
                      setCurrentRemainTime(row.original.remainTime);
                    } else {
                      setCurrentPool(row.original.poolId);
                      setOpenPenaltyBox(true);
                      setCurrentRemainTime(row.original.remainTime);
                    }
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="stake-inform-rule">
                  *Unstake within the first 7 days will only receive 70% of the
                  deposit.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid className="expand-box">
              <Typography className="title">Your reward to claim</Typography>
              <Grid className="value">
                <img src={row.original.rewardLogo} alt="icon" />
                <Typography className="slice-amount">
                  {sliceNumber10Dec(row.original.rewardAmount)}
                </Typography>
                <Typography>{row.original.rewardName}</Typography>
              </Grid>
              <Grid className="btn">
                <CustomBtn
                  text={"Claim"}
                  className={"orange-btn"}
                  variant={"contained"}
                  disabled={
                    row.original.rewardAmount === 0 ||
                    !isAbleClaim(row.original.remainTime)
                      ? true
                      : false
                  }
                  onClick={() => handleClaim(row.original.poolId)}
                />
              </Grid>
              <Grid>
                <Typography className="stake-inform-rule">
                  *Reward will be available to claim after 2 days from the stake
                  request
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ),
    renderEmptyRowsFallback: ({ table }) => (
      <Box className="no-record">No record to display</Box>
    ),
  });

  return (
    <>
      <PenaltyDialog
        open={openPenaltyBox}
        setOpen={setOpenPenaltyBox}
        unstake={handleUnstake}
        remainTime={currentRemainTime}
      />
      <MaterialReactTable table={table} />
    </>
  );
}
