import { Box, Typography } from "@mui/material";
import ComingSoonIcon from "../../assets/coming-soon.png";
import "./coming.css";

export default function ComingSoon() {
  return (
    <Box className="coming-soon-container">
      <img src={ComingSoonIcon} alt="coming soon" />
      <Typography className="title-coming-soon">Coming Soon</Typography>
    </Box>
  );
}
